import { EntityState } from '@reduxjs/toolkit';

import { RootState } from '../../app/rootReducer';
import { BDRequestStatus } from '../models/apis/apiResponse.model';
import { ConstrainedType, Entity } from '../models/common.model';

type EntitySubState = ConstrainedType<RootState, EntityState<Entity>>;

export const populateStateSlice = <
  T extends Entity,
  K extends keyof EntitySubState
>(
  sliceName: K,
  mockEntities: Required<T>[]
): Pick<EntitySubState, K> => {
  return {
    [sliceName]: {
      operations: {},
      status: BDRequestStatus.SUCCEEDED,
      ids: mockEntities.map((entity) => entity.id),
      entities: mockEntities.reduce(
        (dict, entity) => ({ ...dict, [entity.id]: entity }),
        {}
      ),
      errors: [],
    },
  } as unknown as Pick<EntitySubState, K>;
};
