import { Role, User } from '@gm-commercial/profile-model';

import { RootState } from '../../app/rootReducer';
import { ActivationStatus, UserSupportedLocale } from '../models/common.model';
import { createMock } from './objectMock.utils';
import { populateStateSlice } from './sliceMock.utils';

const mockUserNames = [
  'Doctor Who',
  'Ellen Ripley',
  'Darth Vader',
  'Rick Deckard',
  'Han Solo',
  'John Crichton',
  'James Kirk',
  'Jean-Luc Picard',
  'Dana Scully',
  'Fox Mulder',
  'Marty McFly',
  'Luke Skywalker',
];

const roleLevels = Object.values(Role).reduce(
  (levels, roleValue, idx) => ({ ...levels, [roleValue]: idx }),
  {} as { [k in Role]: number }
);

const roles: Role[] = [
  Role.ORG_MANAGER,
  Role.OPERATOR,
  Role.HUB_MANAGER,
  Role.FLEET_MANAGER,
];

const createMockValue = (
  acctChange: number,
  nameChange: string
): Partial<User> => {
  const nameArray = nameChange.split(' ');
  const email =
    nameArray[0].replace(/\W/g, '') +
    '.' +
    nameArray[1].replace(/\W/g, '') +
    '@userTest.com';

  const role = roles[acctChange % 4];
  const hub =
    roleLevels[role] >= roleLevels[Role.HUB_MANAGER]
      ? DEFAULT_MOCK_USER.hub
      : undefined;
  const fleet =
    roleLevels[role] >= roleLevels[Role.FLEET_MANAGER]
      ? DEFAULT_MOCK_USER.fleet
      : undefined;
  return {
    id: (+DEFAULT_MOCK_USER.id + acctChange).toString(),
    name: nameChange,
    role: role,
    hub: hub,
    fleet: fleet,
    firstName: nameArray[0],
    lastName: nameArray[1],
    email: email,
    phoneNumber: '+1' + (40455500 + acctChange).toString() + '01',
  };
};

// TODO: update user role or contexts to make sense
export const DEFAULT_MOCK_USER: Required<User> = {
  id: '500',
  name: 'Alpha Manager',
  firstName: 'Alpha',
  lastName: 'Manager',
  displayName: 'Alpha Manager',
  email: 'alpha.manager@alpha.com',
  phoneNumber: '+14045551212',
  address: {
    address1: '300 Renaissance Ctr',
    city: 'Detroit',
    stateProvinceTerritory: 'MI',
    postalCode: '48243',
    country: 'US',
  },
  status: ActivationStatus.ACTIVE,
  creationDate: '',
  activationDate: '',
  role: Role.SYSTEM_MANAGER,
  organization: {
    id: '100',
    name: 'Burns Industries',
  },
  hub: {
    id: '200',
    name: 'Alpha',
  },
  fleet: {
    id: '300',
    name: 'Starfleet',
  },
  userPreferences: {
    locale: UserSupportedLocale.en_US,
    timeZone: 'America/Los_Angeles',
  },
};

export const MOCK_USERS: Required<User>[] = mockUserNames.map((name, index) => {
  return createMock(DEFAULT_MOCK_USER, createMockValue(index, name));
});

export const mockUsersState: Pick<RootState, 'users'> = {
  users: {
    ...populateStateSlice('users', MOCK_USERS).users,
    sessionConfigs: {},
  },
};
