import { NavBarConfig } from '@gm-commercial/navbar-model';

import {
  ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH,
  ACCOUNT_PROFILE_PERSONAL_PATH,
} from '~/common/models/pages/accountPages.model';

import GearIcon from '../../../assets/icons/new/gearIcon.svg?react';
import ProfileIcon from '../../../assets/icons/new/profile.svg?react';
import { CS_NAVBAR_MESSAGES } from '../CsNavDrawer.messages';

export const getAccountNavBarConfig = ({
  messages,
}: {
  messages: typeof CS_NAVBAR_MESSAGES.accountMenu;
}): NavBarConfig => ({
  title: messages['common:account'],
  homePath: '/',
  open: true,
  toggleable: true,
  items: [
    {
      label: messages['account:profile'],
      testId: 'account-profile',
      icon: <ProfileIcon className="noFill" />,
      path: ACCOUNT_PROFILE_PERSONAL_PATH,
    },
    {
      label: messages['account:preferences'],
      testId: 'account-preferences',
      icon: <GearIcon className="noFill" />,
      path: ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH,
      children: [
        {
          label: messages['account:languageAndRegion'],
          testId: 'account-preferences-language-region',
          path: ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH,
        },
      ],
    },
  ],
});
