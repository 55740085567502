import {
  AssetPermission,
  BaseAction,
  Permission,
  PermissionType,
  Profile,
  ProfileAction,
  Role,
} from '@gm-commercial/profile-model';

import { getDisplayKeys } from '../utils/common.utils';
import { DEFAULT_MOCK_USER } from './user.mock';

export const MOCK_PERMISSIONS: { [k in Role]: Permission[] } = {
  [Role.SYSTEM_MANAGER]: [
    ...getDisplayKeys(PermissionType).reduce(
      (perms, type) => [
        ...perms,
        ...getDisplayKeys(BaseAction).map(
          (action) => `${type}.${action}` as const
        ),
      ],
      [] as Permission[]
    ),
    AssetPermission.MODIFYPROTECTEDVALUES,
  ],
  [Role.SERVICE_TECHNICIAN]: [
    'Organization.Read',
    'Profile.Read',
    'Hub.Read',
    'Fleet.Read',
    'Asset.Read(scope=Technician)' as Permission,
    'Asset.Update(scope=Technician)' as Permission,
    'Asset.PerformOperation(scope=Technician)' as Permission,
    'Asset.ModifyHardwareID(scope=Technician)' as Permission,
    'Asset.ManageHardware(scope=Technician)' as Permission,
  ],
  [Role.ORG_MANAGER]: [
    'Organization.Read',
    'Organization.Update',
    ...getDisplayKeys(PermissionType)
      .filter(
        (type) =>
          type !== PermissionType[PermissionType.Organization] &&
          type !== PermissionType[PermissionType.Order]
      )
      .reduce(
        (perms, type) => [
          ...perms,
          ...getDisplayKeys(BaseAction).map(
            (action) => `${type}.${action}` as const
          ),
        ],
        [] as Permission[]
      ),
  ],
  [Role.HUB_MANAGER]: [
    'Organization.Read',
    'Hub.Read',
    'Hub.Update',
    'Profile.Create',
    'Profile.Update',
    'Profile.Read',
    ...getDisplayKeys(PermissionType)
      .filter(
        (type) =>
          type !== PermissionType[PermissionType.Organization] &&
          type !== PermissionType[PermissionType.Hub] &&
          type !== PermissionType[PermissionType.Profile] &&
          type !== PermissionType[PermissionType.Job] &&
          type !== PermissionType[PermissionType.Order]
      )
      .reduce(
        (perms, type) => [
          ...perms,
          ...getDisplayKeys(BaseAction).map(
            (action) => `${type}.${action}` as const
          ),
          ...getDisplayKeys(ProfileAction).map(
            (action) => `${type}.${action}` as const
          ),
        ],
        [] as Permission[]
      ),
  ],
  [Role.FLEET_MANAGER]: [
    'Organization.Read',
    'Hub.Read',
    'Profile.Create',
    'Profile.Update',
    'Profile.Read',
    ...[
      BaseAction[BaseAction.Read],
      BaseAction[BaseAction.Update],
      ProfileAction[ProfileAction.AssignProfile],
      ProfileAction[ProfileAction.UnassignProfile],
    ].reduce(
      (perms, action) => [
        ...perms,
        ...[PermissionType.Fleet, PermissionType.Asset].map(
          (type) =>
            `${PermissionType[type] as keyof typeof PermissionType}.${
              action as keyof (typeof BaseAction | typeof ProfileAction)
            }` as const
        ),
      ],
      [] as Permission[]
    ),
  ],
  [Role.OPERATOR]: [], // only webapp use case for operator is account registration
  [Role.VIEWER]: [
    ...getDisplayKeys(PermissionType).map(
      (type) => `${type as keyof typeof PermissionType}.${'Read'}` as const
    ),
  ],
  [Role.POC_ROUTEMANAGER]: [
    ...getDisplayKeys(PermissionType).reduce(
      (perms, type) => [
        ...perms,
        ...getDisplayKeys(BaseAction).map(
          (action) => `${type}.${action}` as const
        ),
      ],
      [] as Permission[]
    ),
  ],
  [Role.POC_GROCERYMANAGER]: [
    'Order.Create',
    'Order.Read',
    'Order.Update',
    'Order.Delete',
    'Asset.Read',
    'Asset.Update',
    'Asset.SetHub',
    'Asset.SetFleet',
    'Organization.Read',
    'Fleet.Create',
    'Fleet.Read',
    'Fleet.Update',
    'Hub.Create',
    'Hub.Read',
    'Hub.Update',
    'Profile.Update',
    'Profile.Read',
  ],
  [Role.SYSTEM_READER]: [
    ...getDisplayKeys(PermissionType).reduce(
      (perms, type) => [
        ...perms,
        `${type}.${BaseAction[BaseAction.Read]}` as Permission,
      ],
      [] as Permission[]
    ),
  ],
  [Role.SERVICE_MANAGER]: [
    'Asset.PerformOperation(scope=Technician)' as Permission,
    'Asset.ManageHardware(scope=Technician)' as Permission,
    'Hub.Read',
    'Profile.Read(scope=ServiceManager)' as Permission,
    'Profile.Invite(scope=ServiceManager)' as Permission,
    'Asset.Read(scope=Technician)' as Permission,
    'Asset.Update.State.Availability(scope=Technician)' as Permission,
    'Profile.Update(scope=ServiceManager)' as Permission,
    'Fleet.Read',
    'Asset.ModifyHardwareID(scope=Technician)' as Permission,
    'Organization.Read',
  ],
};

export const DEFAULT_MOCK_PROFILE: Profile = {
  ...DEFAULT_MOCK_USER,
  role: Role.SYSTEM_MANAGER,
  permissions: MOCK_PERMISSIONS[Role.SYSTEM_MANAGER],
};

export const getMockProfile = (
  role: Role,
  permissions?: Permissions
): Profile => {
  let profile = {
    role,
    permissions:
      permissions ||
      Object.prototype.hasOwnProperty.call(MOCK_PERMISSIONS, role)
        ? MOCK_PERMISSIONS[role as keyof typeof MOCK_PERMISSIONS]
        : MOCK_PERMISSIONS[Role.SYSTEM_MANAGER],
  } as Profile;

  const roleLevels = Object.values(Role).reduce(
    (levels, roleValue, idx) => ({ ...levels, [roleValue]: idx }),
    {} as { [k in Role]: number }
  );

  if (roleLevels[role] >= roleLevels[Role.ORG_MANAGER]) {
    profile = {
      ...profile,
      permissionsContextIds: {
        ...profile.permissionsContextIds,
        organizationsId: '5b277e6e-5d03-4e90-b167-4ea7de5d6316',
      },
      organization: {
        id: '5b277e6e-5d03-4e90-b167-4ea7de5d6316',
        name: `Ali's Drone Delivery`,
      },
    };
  }
  if (roleLevels[role] >= roleLevels[Role.HUB_MANAGER]) {
    profile = {
      ...profile,
      permissionsContextIds: {
        ...profile.permissionsContextIds,
        hubsId: '218fb411-be59-44a5-bc83-f8c69e488445',
      },
      hub: {
        id: '218fb411-be59-44a5-bc83-f8c69e488445',
        name: 'Drone Central',
      },
    };
  }
  if (roleLevels[role] >= roleLevels[Role.FLEET_MANAGER]) {
    profile = {
      ...profile,
      permissionsContextIds: {
        ...profile.permissionsContextIds,
        fleetsId: 'f4372c48-cb6b-4c5f-8b48-6a10ba0d3aea',
      },
      fleet: {
        id: 'f4372c48-cb6b-4c5f-8b48-6a10ba0d3aea',
        name: 'Heavy Lifters',
      },
    };
  }

  return profile;
};
