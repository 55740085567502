import { Address } from '@gm-commercial/location-model';
import { Profile, Role } from '@gm-commercial/profile-model';

import { RootState } from '~/app/rootReducer';
import { DTC_SYSTEMS } from '~/features/assets/details/Dtc.model';
import { ProfileRequestType } from '~/features/profile/profileSlice';

import {
  BDRequestStatus,
  BDRequestType,
} from '../models/apis/apiResponse.model';
import {
  type DiagnosticSystem,
  AssetDiagnostics,
  AssetEventTelemetry,
  AssetFault,
  AssetFaultStatus,
  AssetFlags,
  AssetServiceStatus,
  AssetState,
  AssetTelemetry,
  AssetTrip,
  DiagnosticSeverity,
  DistanceUnit,
  ProvisioningStatus,
  RecommendedTirePressures,
  TirePressure,
} from '../models/asset.model';
import {
  ActivationStatus,
  Location,
  UserSupportedLocale,
} from '../models/common.model';
import { BDAppErrorType } from '../models/error.model';
import { FeatureFlagsRequestType } from '../models/featureFlags.model';
import {
  BatteryChargingStatus,
  PowerType,
} from './../models/asset-report.model';
import { MOCK_PERMISSIONS } from './profile.mock';

export const MOCK_LOCATION: Location = {
  latitude: 42.32978,
  longitude: -83.03916,
};

export const MOCK_ADDRESSES: Address[] = [
  {
    address1: '300 Renaissance Ctr',
    city: 'Detroit',
    stateProvinceTerritory: 'MI',
    postalCode: '48243',
    country: 'US',
  },
  {
    address1: '1035 Sun Valley Dr',
    city: 'Roswell',
    stateProvinceTerritory: 'GA',
    postalCode: '30076',
    country: 'US',
  },
  {
    address1: '1908 Colonel Sam Dr',
    city: 'Oshawa',
    stateProvinceTerritory: 'ON',
    postalCode: 'L1H 8P7',
    country: 'CA',
  },
];
export const MOCK_ADDRESS: Address = MOCK_ADDRESSES[0];

export const MOCK_ASSET_TELEMETRY: AssetTelemetry = {
  door: {
    value: 'CLOSED',
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
  },
  lock: {
    value: 'LOCKED',
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
  },
  location: {
    value: MOCK_LOCATION,
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
  },
  diagnostics: {
    value: '0x03',
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
  },
  batteryStatus: {
    battery: {
      value: 75.0,
      deviceTimestamp: '2021-04-29T14:11:40.594545900',
    },
    charge: {
      value: true,
      deviceTimestamp: '2021-04-29T14:11:40.594545900',
    },
  },
  estimatedRange: {
    deviceTimestamp: '2021-12-01T10:44:13.000Z',
    unitOfMeasure: DistanceUnit.KM,
    value: 404.015625,
  },
  brakePadStatuses: {
    front: {
      value: 90.0,
      deviceTimestamp: '2021-08-04T03:26:24.000Z',
    },
  },
  engineAirFilterStatus: {
    value: 70,
    deviceTimestamp: '2021-08-04T03:26:24.000Z',
  },
  oilLifeStatus: {
    value: 60,
    severity: DiagnosticSeverity.INFO,
    deviceTimestamp: '2021-08-04T03:26:24.000Z',
  },
  speedStatus: { value: 112, deviceTimestamp: '2021-04-29T14:11:40.594Z' },
  odometer: {
    value: 73450,
    unitOfMeasure: DistanceUnit.MI,
    deviceTimestamp: '2021-04-29T14:11:40.594Z',
  },
  tirePressures: {
    leftFront: {
      value: 220,
      deviceTimestamp: '2021-04-29T14:11:40.594Z',
    },
    leftRear: {
      value: 220,
      deviceTimestamp: '2021-04-29T14:11:40.594Z',
    },
    rightFront: {
      value: 220,
      deviceTimestamp: '2021-04-29T14:11:40.594Z',
    },
    rightRear: {
      value: 220,
      deviceTimestamp: '2021-04-29T14:11:40.594Z',
    },
  },
  expectedTirePressures: {
    front: {
      value: 220,
      deviceTimestamp: '2021-04-29T14:11:40.594Z',
    },
    rear: {
      value: 220,
      deviceTimestamp: '2021-04-29T14:11:40.594Z',
    },
  },
  thermalStatus: undefined,
  chargingStatus: {
    value: BatteryChargingStatus.CHARGING,
    deviceTimestamp: '2021-04-29T14:11:40.594Z',
  },
  powerType: {
    value: PowerType.AC,
    deviceTimestamp: '2021-04-29T14:11:40.594Z',
  },
  minutesToTargetSoc: {
    value: 1000,
    deviceTimestamp: '2021-04-29T14:11:40.594Z',
  },
  targetSoc: {
    value: 70,
    deviceTimestamp: '2021-04-29T14:11:40.594Z',
  },
  fuelLevel: {
    value: 70,
    deviceTimestamp: '2021-04-29T14:11:40.594Z',
  },
  lifetimeFuelEconomy: {
    value: 10,
    deviceTimestamp: '2021-04-29T14:11:40.594Z',
  },
  lifetimeEfficiency: {
    value: 100,
    deviceTimestamp: '2021-04-29T14:11:40.594Z',
  },
  engineRunTime: {
    value: 3.6e6,
    deviceTimestamp: '2021-04-29T14:11:40.594Z',
  },
};

export const MOCK_ASSET_TRIP: AssetTrip[] = [
  {
    id: '123',
    start: {
      value: {
        ...MOCK_LOCATION,
        address: MOCK_ADDRESSES[0],
      },
      deviceTimestamp: new Date().toISOString(),
    },
    end: {
      value: {
        ...MOCK_LOCATION,
        address: MOCK_ADDRESSES[1],
      },
      deviceTimestamp: new Date().toISOString(),
    },
  },
  {
    id: '345',
    start: {
      value: {
        ...MOCK_LOCATION,
        address: MOCK_ADDRESSES[2],
      },
      deviceTimestamp: new Date().toISOString(),
    },
    end: {
      value: {
        ...MOCK_LOCATION,
        address: MOCK_ADDRESSES[3],
      },
      deviceTimestamp: new Date().toISOString(),
    },
  },
];

export const MOCK_ASSET_STATE: AssetState = {
  connectivity: 'ONLINE',
  diagnostic: 'clear',
  availability: AssetServiceStatus.IN_SERVICE,
  provisioning: ProvisioningStatus.COMPLETED,
};

export const MOCK_ASSET_TELEMETRY_HISTORY: AssetEventTelemetry = {
  location: MOCK_LOCATION,
  battery: 80.0,
  temperature: 38.6,
  diagnostics: '0x03',
  address: { ...MOCK_ADDRESS },
};

export const MOCK_ACTIVATION_STATUSES: ActivationStatus[] =
  Object.values(ActivationStatus);

export const MOCK_DTC_SYSTEMS: DiagnosticSystem[] = [
  { type: DTC_SYSTEMS.antilock_braking, subsystems: [] },
];

export const MOCK_ASSET_DIAGNOSTICS: AssetDiagnostics = {
  tire_flat: {
    value: true,
    severity: DiagnosticSeverity.CRITICAL,
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
  },
  fluid_low_brake: {
    value: true,
    severity: DiagnosticSeverity.CRITICAL,
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
  },
  battery_red: {
    value: true,
    severity: DiagnosticSeverity.CRITICAL,
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
  },
  fluid_low_washer: {
    value: true,
    severity: DiagnosticSeverity.IMPAIRED,
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
  },
  unknown_flag_1: {
    value: true,
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
  },
  unknown_flag_2: {
    value: true,
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
  },
  dtc_B0011: {
    value: true,
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
    severity: DiagnosticSeverity.IMPAIRED,
    mil: true,
    systems: MOCK_DTC_SYSTEMS,
    name: 'Description on diagnostic.',
    text: 'Explanation on diagnostic text.',
  },
  dtc_NoSystems: {
    value: true,
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
    systems: [],
  },
  dtc_NullSystems: {
    value: true,
    deviceTimestamp: '2021-04-29T14:11:40.594545900',
    systems: null,
  },
};

export const MOCK_ASSET_FLAGS: AssetFlags = {
  diagnostics: MOCK_ASSET_DIAGNOSTICS,
  lowBattery: true,
  lowBrakeFluid: true,
  lowWasherFluid: true,
  lowTire: true,
  ready: false,
};

export const MOCK_ASSET_FAULTS: AssetFault[] = [
  {
    id: '1',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.CRITICAL,
    deviceTimestamp: '2021-11-01T16:12:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: true,
    name: '--',
    text: '--',
  },
  {
    id: '2',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.CRITICAL,
    deviceTimestamp: '2021-10-28T11:45:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: true,
    name: '--',
    text: '--',
  },
  {
    id: '3',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.IMPAIRED,
    deviceTimestamp: '2021-10-25T10:35:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: true,
    name: '--',
    text: '--',
  },
  {
    id: '4',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.IMPAIRED,
    deviceTimestamp: '2021-09-15T12:20:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: true,
    name: '--',
    text: '--',
  },
  {
    id: '5',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.IMPAIRED,
    deviceTimestamp: '2021-09-05T10:15:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: true,
    name: '--',
    text: '--',
  },
  {
    id: '6',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.INFO,
    deviceTimestamp: '2021-08-28T16:20:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: false,
    name: '--',
    text: '--',
  },
  {
    id: '7',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.IMPAIRED,
    deviceTimestamp: '2021-08-18T12:11:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: true,
    name: '--',
    text: '--',
  },
  {
    id: '8',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.CRITICAL,
    deviceTimestamp: '2021-08-08T06:40:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: true,
    name: '--',
    text: '--',
  },
  {
    id: '9',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.CRITICAL,
    deviceTimestamp: '2021-07-29T09:11:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: true,
    name: '--',
    text: '--',
  },
  {
    id: '10',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.INFO,
    deviceTimestamp: '2021-07-28T16:19:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: false,
    name: '--',
    text: '--',
  },
  {
    id: '11',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.INFO,
    deviceTimestamp: '2021-07-26T13:11:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: false,
    name: '--',
    text: '--',
  },
  {
    id: '12',
    code: 'dtc_U12323',
    severity: DiagnosticSeverity.CRITICAL,
    deviceTimestamp: '2021-07-20T11:30:40.594545900',
    status: AssetFaultStatus.ACTIVE,
    mil: true,
    name: '--',
    text: '--',
  },
];

export const MOCK_ASSET_TIRE_PRESSURE: TirePressure = {
  leftFront: {
    value: 414,
    deviceTimestamp: '2021-12-08T20:55:21.170Z',
    severity: 'info' as DiagnosticSeverity,
  },
  leftRear: {
    value: 483,
    deviceTimestamp: '2021-12-08T20:55:21.170Z',
    severity: 'info' as DiagnosticSeverity,
  },
  rightFront: {
    value: 414,
    deviceTimestamp: '2021-12-08T20:55:21.170Z',
    severity: 'info' as DiagnosticSeverity,
  },
  rightRear: {
    value: 483,
    deviceTimestamp: '2021-12-08T20:55:21.170Z',
    severity: 'info' as DiagnosticSeverity,
  },
};

export const MOCK_EXPECTED_TIRE_PRESSURES: RecommendedTirePressures = {
  front: {
    value: 483,
    deviceTimestamp: '2021-12-08T20:55:21.170Z',
  },
  rear: {
    value: 414,
    deviceTimestamp: '2021-12-08T20:55:21.170Z',
  },
};

export const mockDefaultInitialState: Partial<RootState> = {
  auth: {
    authToken: { value: 'mockAuthToken', expiration: 123456789 },
    status: BDRequestStatus.SUCCEEDED,
    operation: BDRequestType.AUTH,
    loginAttempt: 0,
    forceLogout: false,
    _persist: { version: -1, rehydrated: false },
  },
  profile: {
    operations: {
      [ProfileRequestType.GET_PROFILE]: {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      },
      [ProfileRequestType.GET_PERMISSIONS]: {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      },
      [ProfileRequestType.GET_ALLOWED_CONTEXTS]: {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      },
    },
    profile: {
      role: Role.SYSTEM_MANAGER,
      permissions: MOCK_PERMISSIONS[Role.SYSTEM_MANAGER],
    } as Profile,
    actingProfile: {
      role: Role.SYSTEM_MANAGER,
      permissions: MOCK_PERMISSIONS[Role.SYSTEM_MANAGER],
    } as Profile,
    currentLocale: UserSupportedLocale.en_US,
    _persist: { version: -1, rehydrated: false },
  },
  featureFlags: {
    operations: {
      [FeatureFlagsRequestType.GET_FEATURE_FLAGS]: {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      },
    },
    ids: [],
    entities: {},
  },
};

export const mockDuplicateEmailError = {
  type: '',
  meta: { requestStatus: 'rejected' },
  payload: {
    status: 400,
    message: 'Request failed with status code 400',
    name: 'Error',
    response: {
      errors: [
        {
          errorCode: 'INVALID_VALUE',
          errorType: BDAppErrorType.VALIDATION,
          field: 'emailAddress',
          message: 'A profile with the provided email address already exists',
        },
      ],
    },
  },
};

export const mockDuplicateNameError = {
  type: '',
  meta: { requestStatus: 'rejected' },
  payload: {
    status: 400,
    message: 'Request failed with status code 400',
    name: 'Error',
    response: {
      errors: [
        {
          name: 'Invalid Value error',
          errorCode: 'INVALID_VALUE',
          errorType: BDAppErrorType.VALIDATION,
          field: 'name',
          message: 'A profile with the provided name already exists',
        },
      ],
    },
  },
};

export const mockNavDrawerState: Pick<RootState, 'navDrawer'> = {
  navDrawer: {
    isExpanded: false,
  },
};
