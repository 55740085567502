import { useTranslations } from '@brightdrop/localization-client';
import { cx } from '@emotion/css';
import { usePermissions, useRole } from '@gm-commercial/profile-context';
import {
  AlertPermission,
  ProfilePermission,
  Role,
} from '@gm-commercial/profile-model';
import {
  AppBar,
  Box,
  Button,
  Container,
  Link as ButtonLink,
  Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';

import { useAppDispatch } from '~/app/store';
import { SecureWrapper } from '~/common/components';
import DelayedContent from '~/common/components/delayedContent/DelayedContent';
import useActingProfile from '~/common/hooks/useActingProfile';
import useCustomAuthentication from '~/common/hooks/useCustomAuthentication';
import useProfileWithPermissions from '~/common/hooks/useProfileWithPermissions';
import { NOTIFICATIONS_REQUIRED_ROLES } from '~/common/models/notification.model';
import { ACCOUNT_PROFILE_PERSONAL_PATH } from '~/common/models/pages/accountPages.model';
import { SETTINGS_NOTIFICATIONS_VEHICLES } from '~/common/models/pages/settingsPages.model';
import { AppRoutePaths } from '~/common/models/route.model';
import {
  getNavDisabledPaths,
  getProfileDisabled,
} from '~/common/utils/route/route.utils';

import VersionRefresher from '../../app/versionRefresher/VersionRefresher';
import { setActingProfile } from '../profile/profileSlice';
import {
  selectActingPermissionsContextIds,
  selectActingProfileFirstName,
  selectActingProfileFleet,
  selectActingProfileHub,
  selectActingProfileId,
  selectActingProfileLastName,
  selectActingProfileOrganization,
  selectActingProfileStatus,
  selectActingRole,
} from '../profile/profileSlice.selectors';
import ContextSwitcher from './components/ContextSwitcher/ContextSwitcher';
import NotificationBadge from './components/NotificationBadge/NotificationBadge';
import ProfileMenu from './components/ProfileMenu/ProfileMenu';
import useStyles from './Header.styles';

const MESSAGES = {
  'common:header.openUserMenu': 'Open user menu',
  'common:header.profileMenu': 'Profile menu',
  'common:header.logout': 'Logout',
  'common:header.support': 'Support',
  'common:navDrawer.openNav': 'Open navigation drawer',
  'common:header.removeSettings': 'Remove Settings',
  'common:account': 'Account',
  'common:settings': 'Settings',
  roleList: {
    [`common:roleList.${Role.ORG_MANAGER}`]: 'Account Admin',
    [`common:roleList.${Role.HUB_MANAGER}`]: 'Hub Manager',
    [`common:roleList.${Role.FLEET_MANAGER}`]: 'Fleet Manager',
    [`common:roleList.${Role.OPERATOR}`]: 'Operator',
    [`common:roleList.${Role.SYSTEM_MANAGER}`]: 'Advisor',
    [`common:roleList.${Role.SERVICE_TECHNICIAN}`]: 'Service Technician',
    [`common:roleList.${Role.SYSTEM_READER}`]: 'System Reader',
    [`common:roleList.${Role.POC_GROCERYMANAGER}`]: 'Grocery Manager',
    [`common:roleList.${Role.SERVICE_MANAGER}`]: 'Service Manager',
  },
};

const selectActingProfileParams = createSelector(
  selectActingProfileId,
  selectActingProfileFirstName,
  selectActingProfileLastName,
  selectActingRole,
  selectActingProfileStatus,
  selectActingProfileOrganization,
  selectActingPermissionsContextIds,
  (usersId, firstName, lastName, role, status, organization, contextIds) => {
    return {
      usersId,
      firstName,
      lastName,
      role,
      status,
      organization,
      ...contextIds,
    };
  }
);

const selectViewAsProfileParams = createSelector(
  selectActingRole,
  selectActingProfileOrganization,
  selectActingProfileHub,
  selectActingProfileFleet,
  (role, organization, hub, fleet) => ({ role, organization, hub, fleet })
);

const SUPPORTED_VIEW_AS_ROLES = [
  Role.ORG_MANAGER,
  Role.HUB_MANAGER,
  Role.FLEET_MANAGER,
];

const ViewAsHeader = (): JSX.Element => {
  const { classes } = useStyles();
  const { translations } = useTranslations(MESSAGES);
  const dispatch = useAppDispatch();
  const viewAsProfile = useSelector(selectViewAsProfileParams);

  const { profile, isLoading, isUpdating } = useProfileWithPermissions();
  const { isActing } = useActingProfile();

  const isProfileInProgress = useMemo(
    () => isLoading || isUpdating,
    [isLoading, isUpdating]
  );
  const actingRole = useMemo(
    () =>
      !!viewAsProfile.role &&
      SUPPORTED_VIEW_AS_ROLES.includes(viewAsProfile.role)
        ? viewAsProfile.role
        : undefined,
    [viewAsProfile.role]
  );

  const actingScope = useMemo(() => {
    if (actingRole) {
      switch (actingRole) {
        case Role.ORG_MANAGER: {
          return viewAsProfile.organization?.name;
        }
        case Role.HUB_MANAGER: {
          return viewAsProfile.hub?.name;
        }
        case Role.FLEET_MANAGER: {
          return viewAsProfile.fleet?.name;
        }
      }
    }
    return undefined;
  }, [viewAsProfile, actingRole]);

  const headerMessage = useMemo(() => {
    if (actingRole) {
      return (
        <Trans
          i18nKey={
            actingScope
              ? 'common:viewAs.headerMessage'
              : 'common:viewAs.headerMessage_noName'
          }
          values={{
            role: translations.roleList[
              `common:roleList.${actingRole}` as keyof typeof translations['roleList']
            ],
            entity: actingScope,
          }}
        />
      );
    }
    return undefined;
  }, [translations, actingRole, actingScope]);

  const handleActingProfileToggle = useCallback(() => {
    dispatch(setActingProfile());
  }, [dispatch]);

  return (
    <>
      <DelayedContent
        isLoading={isProfileInProgress}
        data={profile}
        skeleton={<></>}
      >
        {isActing && !isProfileInProgress && (
          <AppBar
            className={classes.viewAsHeaderWrapper}
            position="sticky"
            elevation={0}
          >
            <Container className={classes.viewAsContainer} maxWidth="xl">
              <Box className={classes.viewAsMessageContainer}>
                <span data-testid="view-message">{headerMessage}</span>
                <ButtonLink
                  component="button"
                  variant="body2_deprecated"
                  className={classes.viewAslink}
                  onClick={handleActingProfileToggle}
                  data-testid="remove-settings-link"
                >
                  {translations['common:header.removeSettings']}
                </ButtonLink>
              </Box>
            </Container>
          </AppBar>
        )}
      </DelayedContent>
    </>
  );
};

const Header = (): JSX.Element => {
  const { classes } = useStyles();
  const { translations } = useTranslations(MESSAGES);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const menuId = 'account-menu';
  const { hasPermission } = usePermissions();
  const { hasRole } = useRole();
  const actingProfileParams = useSelector(selectActingProfileParams);
  const location = useLocation();
  const matchUnsupportedRoute = matchPath(location.pathname, {
    path: getNavDisabledPaths(),
  });
  const matchSessionRoute = matchPath(location.pathname, {
    path: [AppRoutePaths.INVITE_REQUIRED, AppRoutePaths.SERVICE_UNAVAILABLE],
  });
  const unSupportedRoute = matchPath(location.pathname, {
    path: getProfileDisabled(),
  });

  //TEMPORARY FIX TO ALLOW THE HEADER TO SHOW UP ON INVITE REQUIRED PAGE
  //TODO: will remove once invite-required page is decommissioned
  const matchDevRequiredProfileRoute = matchPath(location.pathname, {
    path: [AppRoutePaths.INVITE_REQUIRED],
  });

  const disableProfile = useMemo(() => !!unSupportedRoute, [unSupportedRoute]);
  const suppressNavigation = useMemo(
    () =>
      !!matchUnsupportedRoute ||
      (!actingProfileParams.usersId && !matchDevRequiredProfileRoute),
    [
      matchUnsupportedRoute,
      actingProfileParams.usersId,
      matchDevRequiredProfileRoute,
    ]
  );
  const canUpdateProfile = useMemo(
    () => hasPermission([ProfilePermission.UPDATE]),
    [hasPermission]
  );
  const { authenticationType } = useCustomAuthentication();
  const PROFILE_MENU = useMemo(
    () => [
      {
        label: 'common:header.logout',
        testId: 'logout-menu',
        onClick: { redirect: `/auth/${authenticationType}/logout` },
        section: 'bottom',
      },
    ],
    [authenticationType]
  );

  const updatedProfileMenu = useMemo(() => {
    const { role, usersId } = actingProfileParams;
    const isNotificationAllowed = hasRole(NOTIFICATIONS_REQUIRED_ROLES);
    const updatedProfileMenu =
      usersId &&
      role !== Role.OPERATOR &&
      !suppressNavigation &&
      !disableProfile &&
      canUpdateProfile
        ? [
            ...[
              {
                label: 'common:account',
                testId: 'account-menu',
                onClick: {
                  redirect: ACCOUNT_PROFILE_PERSONAL_PATH,
                },
                section: 'top',
              },
            ],
            ...(isNotificationAllowed
              ? [
                  {
                    label: 'common:settings',
                    testId: 'settings-menu',
                    onClick: {
                      redirect: SETTINGS_NOTIFICATIONS_VEHICLES,
                    },
                    section: 'top',
                  },
                ]
              : []),
            ...[],
            ...PROFILE_MENU,
          ]
        : PROFILE_MENU;

    return updatedProfileMenu;
  }, [
    actingProfileParams,
    hasRole,
    suppressNavigation,
    disableProfile,
    canUpdateProfile,
    PROFILE_MENU,
  ]);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const theme = useTheme();
  const isDesktopSmallViewport = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <VersionRefresher />
      <ViewAsHeader />
      <AppBar
        className={cx(classes.headerWrapper, {
          hideBar: suppressNavigation,
        })}
        position={isDesktopSmallViewport ? 'sticky' : 'relative'}
        color="inherit"
      >
        <Box className={classes.container}>
          <ContextSwitcher />
          <Toolbar
            disableGutters
            className={classes.header}
            sx={{ padding: 0, minHeight: 0 }}
          >
            <SecureWrapper
              requiredPermissions={[AlertPermission.READ]}
              requiredRoles={NOTIFICATIONS_REQUIRED_ROLES}
            >
              <NotificationBadge />
            </SecureWrapper>
            <SecureWrapper
              requiredPermissions={[]}
              passThrough={!!matchSessionRoute}
            >
              <div>
                <Button
                  id="user-menu-btn"
                  aria-label={translations['common:header.openUserMenu']}
                  aria-controls={menuId}
                  aria-haspopup="true"
                  color="primary"
                  onClick={handleMenu}
                  className={classes.profileContainerButton}
                >
                  <div
                    className={cx(classes.profileContainer, {
                      isOpen: !!anchorEl,
                    })}
                  >
                    {actingProfileParams?.firstName?.charAt(0)}
                    {actingProfileParams?.lastName?.charAt(0)}
                  </div>
                </Button>
                <ProfileMenu
                  label={translations['common:header.profileMenu']}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  items={updatedProfileMenu.map(
                    ({ label, testId, onClick, section }) => ({
                      label:
                        translations[
                          label as keyof Omit<typeof translations, 'roleList'>
                        ],
                      testId,
                      onClick: { redirect: onClick?.redirect },
                      section: section,
                    })
                  )}
                />
              </div>
            </SecureWrapper>
          </Toolbar>
        </Box>
      </AppBar>
    </>
  );
};

export default Header;
