import { Box, SvgIcon } from '@mui/material';

import { iconRenderer } from '~/common/components/table/renderers/columnRenderer';
import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import {
  AssetReportItem,
  ReportDiagnosticState,
} from '~/common/models/asset-report.model';
import { TableColumnConfig } from '~/common/models/table/table.model';

import FaultIcon from '../../../../assets/icons/utils/severeFaultIcon.svg?react';
import { ASSET_LIST_REPORT_MESSAGES } from '../AssetListReportView.messages';
import {
  getConnectivityBoardingStatusStatus,
  getFuelLevelDiagnosticStatus,
  getReportDiagnosticStatus,
} from '../utils/report.utils';
import {
  ASSET_REPORT_ADAPTERS,
  BRAKE_FLUID_COLUMN,
  DIAGNOSTICS_COLUMN,
  FUEL_BATTERY_COLUMN,
  getIdentityReportColumns,
  LAST_COMMUNICATED_COLUMN,
  OIL_LIFE_COLUMN,
  READINESS_COLUMN,
  SERVICE_COLUMN,
  WASHER_FLUID_COLUMN,
} from './commonReportTable.config';

export const getReadinessReportColumns = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels,
  isParamAvailable: boolean,
  locale?: string
) => {
  const columns = [
    ...getIdentityReportColumns(columnLabels, isParamAvailable),
    CONNECTIVITY_STATUS_COLUMN(columnLabels, valueLabels),
    DIAGNOSTICS_COLUMN(columnLabels, valueLabels),
    SERVICE_COLUMN(columnLabels, valueLabels),
    READINESS_COLUMN(columnLabels),
    LAST_COMMUNICATED_COLUMN(columnLabels, valueLabels, locale),
    FUEL_BATTERY_COLUMN(columnLabels),
    FUEL_COLUMN(columnLabels),
    TIRES_COLUMN(columnLabels, valueLabels),
    BRAKE_FLUID_COLUMN(columnLabels, valueLabels),
    WASHER_FLUID_COLUMN(columnLabels, valueLabels),
    ENGINE_AIR_FILTER_COLUMN(columnLabels, valueLabels),
    BRAKE_PAD_FRONT_COLUMN(columnLabels),
    BRAKE_PAD_REAR_COLUMN(columnLabels),
    FAULTS_COLUMN(columnLabels, valueLabels),
    OIL_LIFE_COLUMN(columnLabels, valueLabels),
  ];
  return columns;
};

export const TIRES_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'tires',
  label: columnLabels['asset:tableColumnLabels.tireStatus'],
  options: {
    setCellProps: () => ({ style: { minWidth: '110px' } }),
    sort: false,
    customBodyRender: (value) => {
      return getReportDiagnosticStatus(
        'tire',
        value,
        ASSET_REPORT_ADAPTERS.tireStatus(valueLabels, value)
      );
    },
  },
});

export const FUEL_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'fuel',
  label: columnLabels['asset:fuel'],
  options: {
    setCellProps: () => ({ style: { minWidth: '110px' } }),
    sort: false,
    display: false,
    customBodyRender: (fuel) => {
      if (typeof fuel?.value === 'number') {
        return getFuelLevelDiagnosticStatus(
          'fuel-level',
          fuel.state,
          ASSET_REPORT_ADAPTERS.fuel(fuel)
        );
      }
      return (
        <Box component="span" data-testid="no-data-diagnostic-status">
          {DEFAULT_EMPTY_VALUE}
        </Box>
      );
    },
  },
});

export const FAULTS_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'milState',
  label: columnLabels['asset:tableColumnLabels.faults'],
  options: {
    sort: false,
    customBodyRender: (value, tableMeta) => {
      return iconRenderer({
        iconConfig: {
          [ReportDiagnosticState.CRITICAL]: (
            <Box className="criticalFaultIcon">
              <FaultIcon
                title={
                  valueLabels[
                    `asset:filterModalLabels.faultStatus.${ReportDiagnosticState.CRITICAL}`
                  ]
                }
              />
            </Box>
          ),
          [ReportDiagnosticState.IMPAIRED]: (
            <Box className="impairedFaultIcon">
              <SvgIcon
                component={() => (
                  <FaultIcon
                    title={
                      valueLabels[
                        `asset:filterModalLabels.faultStatus.${ReportDiagnosticState.IMPAIRED}`
                      ]
                    }
                  />
                )}
              ></SvgIcon>
            </Box>
          ),
          [ReportDiagnosticState.CLEAR]: (
            <Box>
              {
                valueLabels[
                  `common:propertyState.${ReportDiagnosticState.CLEAR}`
                ]
              }
            </Box>
          ),
        },
      })(value, tableMeta);
    },
  },
});

export const BRAKE_PAD_FRONT_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'brakePadFront',
  label: columnLabels['asset:brakePadFront'],
  options: {
    setCellProps: () => ({ style: { minWidth: '100px' } }),
    sort: false,
    customBodyRender: (brakePadFront) => {
      if (brakePadFront?.state) {
        return getReportDiagnosticStatus(
          'brakePadFront',
          brakePadFront.state,
          ASSET_REPORT_ADAPTERS.brakePadFront(brakePadFront)
        );
      }
      return DEFAULT_EMPTY_VALUE;
    },
  },
});

export const BRAKE_PAD_REAR_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'brakePadRear',
  label: columnLabels['asset:brakePadRear'],
  options: {
    setCellProps: () => ({ style: { minWidth: '100px' } }),
    sort: false,
    customBodyRender: (brakePadRear) => {
      if (brakePadRear?.state) {
        return getReportDiagnosticStatus(
          'brakePadRear',
          brakePadRear.state,
          ASSET_REPORT_ADAPTERS.brakePadRear(brakePadRear)
        );
      }
      return DEFAULT_EMPTY_VALUE;
    },
  },
});

export const ENGINE_AIR_FILTER_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'engineAirFilter',
  label: columnLabels['asset:vehicle.engineAirFilter'],
  options: {
    setCellProps: () => ({ style: { minWidth: '110px' } }),
    sort: false,
    customBodyRender: (engineAirFilter) => {
      if (engineAirFilter?.state) {
        return getReportDiagnosticStatus(
          'engine-air-filter',
          engineAirFilter.state,
          ASSET_REPORT_ADAPTERS.engineAirFilter(valueLabels, engineAirFilter)
        );
      }
      return DEFAULT_EMPTY_VALUE;
    },
  },
});

export const CONNECTIVITY_STATUS_COLUMN = (
  columnLabels: typeof ASSET_LIST_REPORT_MESSAGES.columnLabels,
  valueLabels: typeof ASSET_LIST_REPORT_MESSAGES.valueLabels
): TableColumnConfig<AssetReportItem> => ({
  name: 'connectivityBoardingStatus',
  label: columnLabels['common:status'],
  options: {
    sort: false,
    customBodyRender: (connectivityBoardingStatus) => {
      return getConnectivityBoardingStatusStatus(
        connectivityBoardingStatus,
        ASSET_REPORT_ADAPTERS.connectivityBoardingStatus(
          valueLabels,
          connectivityBoardingStatus
        )
      );
    },
  },
});
