import { add, isBefore, isDate } from '../utils/date-time.utils';

export const getDateFromNow = (days: number): Date => add(new Date(), { days });

export const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min)) + min);
};

export const getRandomItem = <T>(items: T[]): T => {
  return items[Math.floor(Math.random() * items.length)];
};

type DateTimeOffsets = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};
export const getRandomDate = (
  options = {
    seed: new Date(),
    before: { days: 90, hours: 12, minutes: 30, seconds: 30 },
    after: { days: 90, hours: 12, minutes: 30, seconds: 30 },
    allowFuture: false,
  } as {
    seed?: Date;
    before?: DateTimeOffsets;
    after?: DateTimeOffsets;
    allowFuture?: boolean;
  }
): Date => {
  const sanitizeLimits = (
    limits: DateTimeOffsets
  ): Required<DateTimeOffsets> => {
    const sanitized: Required<DateTimeOffsets> = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    Object.keys(limits).forEach((k) => {
      const key = k as keyof DateTimeOffsets;
      const limit = limits[key];
      if (typeof limit === 'number' && limit >= 0) {
        sanitized[key] = limit;
      }
    });
    return sanitized;
  };

  const initial =
    options.seed && isDate(options.seed) ? options.seed : new Date();
  const limitBefore = sanitizeLimits(
    options.before || { days: 90, hours: 12, minutes: 30, seconds: 30 }
  );
  const limitAfter = sanitizeLimits(
    options.after || { days: 90, hours: 12, minutes: 30, seconds: 30 }
  );

  const offsets: DateTimeOffsets = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  Object.keys(offsets).forEach((k) => {
    const key = k as keyof typeof offsets;
    offsets[key] =
      Math.floor(Math.random() * limitAfter[key]) -
      Math.floor(Math.random() * limitBefore[key]);
  });

  const newDate = add(initial, offsets);

  return options.allowFuture
    ? newDate
    : isBefore(newDate, new Date())
    ? newDate
    : new Date();
};
