import { EntityState } from '@reduxjs/toolkit';

import { ActionModel, AssetEvent } from '../../models/asset.model';
import { Location } from '../../models/common.model';
import { isBefore } from '../../utils/date-time.utils';
import { MOCK_ASSET_TELEMETRY_HISTORY } from '../common.mock';
import { getRandomDate, getRandomInt } from '../commonMock.utils';
import { createMock } from '../objectMock.utils';

const createMockValue = (acctChange: number): Partial<AssetEvent> => {
  let assetHistroy = {
    id: (+DEFAULT_MOCK_ASSET_HISTORY.id + acctChange).toString(),
    action: mockAssetAction[getRandomInt(0, mockAssetAction.length - 1)],
    state: {
      ...MOCK_ASSET_TELEMETRY_HISTORY,
      battery: DEFAULT_MOCK_ASSET_HISTORY?.state?.battery
        ? DEFAULT_MOCK_ASSET_HISTORY.state?.battery -
          getRandomInt(0, acctChange % 50)
        : undefined,
      location: MOCK_LOCATIONS[acctChange],
    },
    timestamp: getRandomDate().toUTCString(),
  };
  if (acctChange === 5) {
    //one data without any telemetry for demo purposes
    assetHistroy = Object.assign(assetHistroy, { state: {} });
  }

  if (assetHistroy.action.type !== 'Door') {
    return Object.assign(assetHistroy, {
      actor: {
        id: (500 + acctChange).toString(),
        method: null,
        name: mockOperator[getRandomInt(0, mockOperator.length - 1)],
      },
    });
  } else return Object.assign(assetHistroy, { actor: undefined });
};

const mockAssetAction: ActionModel[] = [
  { type: 'Lock', value: 'LOCKED' },
  { type: 'Lock', value: 'UNLOCKED' },
  { type: 'Door', value: 'OPEN' },
  { type: 'Door', value: 'CLOSED' },
];

const mockOperator = [
  'Denise Green',
  'Amy Flores',
  'Evelyn Harris',
  'Autolock',
  'Michelle Perry',
];

export const DEFAULT_MOCK_ASSET_HISTORY: Required<AssetEvent> = {
  id: '400000',
  assetId: '400',
  action: mockAssetAction[0],
  actor: { id: '500', method: null, name: mockOperator[0] },
  timestamp: getRandomDate().toUTCString(),
  state: MOCK_ASSET_TELEMETRY_HISTORY,
};

export const TRIP_MOCK_ASSET_HISTORY: AssetEvent[] = [
  {
    ...DEFAULT_MOCK_ASSET_HISTORY,
    id: '456',
    action: { type: 'Power', value: 'OFF' },
  },
  {
    ...DEFAULT_MOCK_ASSET_HISTORY,
    id: '123',
    action: { type: 'Power', value: 'ON' },
  },
  {
    ...DEFAULT_MOCK_ASSET_HISTORY,
    id: '321',
    action: { type: 'Power', value: 'OFF' },
  },
  {
    ...DEFAULT_MOCK_ASSET_HISTORY,
    id: '789',
    action: { type: 'Power', value: 'ON' },
  },
];

const eventSize = getRandomInt(15, 100);

export const MOCK_LOCATIONS: Location[] = new Array(eventSize)
  .fill(null)
  .map(() => {
    return {
      latitude:
        (DEFAULT_MOCK_ASSET_HISTORY.state.location?.latitude as number) +
        Math.random(),
      longitude:
        (DEFAULT_MOCK_ASSET_HISTORY.state.location?.longitude as number) +
        Math.random(),
    };
  });

export const MOCK_ASSET_HISTORY: Required<AssetEvent>[] = new Array(eventSize)
  .fill(null)
  .reduce(
    (collector, _, index) => {
      const newAssetHistory = createMock(
        DEFAULT_MOCK_ASSET_HISTORY,
        createMockValue(index)
      );
      if (index === eventSize - 1 && !collector.history.valid) {
        newAssetHistory.state = DEFAULT_MOCK_ASSET_HISTORY.state;
      }
      collector.history.push(newAssetHistory);
      if (!collector.history.valid && newAssetHistory.state) {
        collector.history.valid = true;
      }
      return collector;
    },
    { history: new Array<Required<AssetEvent>>(), valid: false }
  ).history;

export const getMockAssetEventsAdapterState = (assetId?: string) => {
  const ids = MOCK_ASSET_HISTORY.sort((a, b) => {
    if (a.timestamp === b.timestamp) {
      return a.id.localeCompare(b.id);
    }
    return isBefore(new Date(a.timestamp), new Date(b.timestamp)) ? 1 : -1;
  }).map((e) => e.id);
  const entities = MOCK_ASSET_HISTORY.reduce((state, event) => {
    if (event.id) {
      const mockEvent = assetId ? { ...event, assetId } : event;
      return { ...state, [event.id]: mockEvent };
    }
    return state;
  }, {} as { [k: string]: AssetEvent });
  return { ids, entities } as EntityState<AssetEvent>;
};
