import { Dictionary } from '@reduxjs/toolkit';

import { RootState } from '~/app/rootReducer';
import {
  ApiBatteryReadinessItem,
  ApiReadinessItem,
  ApiTiresReadinessItem,
  mapApiAssetReadinessToReportItem,
} from '~/features/assets/report/mappers/asset-report.mappers';

import {
  ApiAsset,
  ApiAssetMapItem,
  ApiAssetType,
  Asset,
  AssetComplianceDetail,
  AssetConnectivityStatus,
  AssetEvent,
  AssetFaultStatus,
  AssetModel,
  AssetOperationalStatus,
  AssetProductModel,
  AssetServiceStatus,
  AssetType,
  ChargingSchedule,
  DiagnosticSeverity,
  DiagnosticState,
  OrganizationOwnershipStatus,
  ProvisioningStatus,
  SafetyComplianceDetail,
  SafetyComplianceStatusDetail,
} from '../../models/asset.model';
import {
  AssetReportItem,
  BatteryChargingStatus,
  DiagnosticDTCType,
  PowerType,
  ReadyStatus,
  ReportDiagnosticState,
  VehiclePropulsion,
} from '../../models/asset-report.model';
import { lowercaseLiteral } from '../../utils/common.utils';
import { endDateTime, startDateTime } from '../../utils/date-time.utils';
import {
  MOCK_ASSET_FLAGS,
  MOCK_ASSET_STATE,
  MOCK_ASSET_TELEMETRY,
  MOCK_LOCATION,
} from '../common.mock';
import { createMock } from '../objectMock.utils';
import { populateStateSlice } from '../sliceMock.utils';
import { getMockAssetEventsAdapterState } from './assetHistory.mock';

const createMockValue = (
  acctChange: number,
  nameChange: string
): Partial<Asset> => {
  return {
    id: (+DEFAULT_MOCK_ASSET.id + acctChange).toString(),
    name: nameChange,
    status: {
      ...DEFAULT_MOCK_ASSET.status,
      location: {
        value: {
          latitude: MOCK_LOCATION.latitude + Math.random() * 0.09,
          longitude: MOCK_LOCATION.longitude + Math.random() * 0.06,
        },
        deviceTimestamp: '2021-04-29T14:11:40.594545900',
      },
    },
    organization: {
      id: '100',
      name: 'Burns Industries',
    },
  };
};

export const mockAssetNames = [
  'Orion',
  'Aries',
  'Andromeda',
  'Cassiopeia',
  'EarthForce',
  'Perseus',
  'Hercules',
  'Ursa Major',
  'Canis Major',
  'Auriga',
  'Draco',
];

export const MOCK_API_ASSET: Partial<ApiAsset> = {
  id: '123',
  name: 'Mock Name',
  state: {
    connectivity: 'ONLINE',
    availability: AssetServiceStatus.IN_SERVICE,
    provisioning: ProvisioningStatus.COMPLETED,
    diagnostic: DiagnosticState.CLEAR,
  },
  status: {
    battery: {
      value: 50,
      deviceTimestamp: '2021-08-04',
    },
    location: {
      value: { lat: 10, lon: 10 },
      deviceTimestamp: '2021-08-04',
    },
    locked: {
      value: true,
      deviceTimestamp: '2021-08-04',
    },
    door: {
      value: true,
      deviceTimestamp: '2021-08-04T03:26:24',
    },
    charge_active: {
      value: true,
      deviceTimestamp: '2021-08-04T03:26:24.000Z',
    },
    odometer: {
      value: 404.015625,
      deviceTimestamp: '2021-08-04T03:26:24.000Z',
    },
    estimated_range: {
      value: { value: 120.015625, uom: 'KM' },
      deviceTimestamp: '2021-08-04T03:26:24.000Z',
    },
    brake_pad_life_front_pct: {
      value: 90.0,
      deviceTimestamp: '2021-08-04T03:26:24.000Z',
    },
    engine_air_filter_life_in_pct: {
      value: 70,
      deviceTimestamp: '2021-08-04T03:26:24.000Z',
    },
    oil_life: {
      value: 60,
      deviceTimestamp: '2021-08-04T03:26:24.000Z',
    },
    tire_driver_front_act: {
      value: 228,
      deviceTimestamp: '2021-10-27T15:18:42.0000000Z',
    },
    tire_driver_rear_act: {
      value: 228,
      deviceTimestamp: '2021-10-27T15:18:42.0000000Z',
    },
    tire_pass_front_act: {
      value: 228,
      deviceTimestamp: '2021-10-27T15:18:42.0000000Z',
    },
    tire_pass_rear_act: {
      value: 228,
      deviceTimestamp: '2021-10-27T15:18:42.0000000Z',
    },
    tires_front_exp: {
      value: 228,
      deviceTimestamp: '2021-10-27T15:18:42.0000000Z',
    },
    tires_rear_exp: {
      value: 228,
      deviceTimestamp: '2021-10-27T15:18:42.0000000Z',
    },
    charging_session: {
      value: BatteryChargingStatus.CHARGING,
      deviceTimestamp: '2021-10-27T15:18:42.0000000Z',
    },
    charging_power_type: {
      value: PowerType.DC,
      deviceTimestamp: '2021-10-27T15:18:42.0000000Z',
    },
    charging_minutes_remaining: {
      value: 34,
      deviceTimestamp: '2021-10-27T15:18:42.0000000Z',
    },
    charging_target_soc: {
      value: 90,
      deviceTimestamp: '2021-10-27T15:18:42.0000000Z',
    },
    fuel_level_in_pct: {
      value: 40,
      deviceTimestamp: '2021-10-27T15:18:42.000Z',
    },
    lifetime_fuel_economy_in_kmpl: {
      value: 10,
      deviceTimestamp: '2021-04-29T14:11:40.594Z',
    },
    engine_run_time_total_in_sec: {
      value: 3.6e6,
      deviceTimestamp: '2021-04-29T14:11:40.594Z',
    },
  },
  diagnostics: {
    brake_pad_life_front_yellow: {
      value: true,
      severity: DiagnosticSeverity.IMPAIRED,
      deviceTimestamp: '2021-08-04T03:26:24.000Z',
    },
  },
  configuration: {
    autolockTimeout: 3000,
    autolockEnabled: true,
    proximityUnlockEnabled: false,
    chargingScheduleTargetChargeLevel: 60,
  },
  type: { model: 'EP1', version: '1.0' },
  vehicleType: {
    model: 'mockModel',
    make: 'mockMake',
    year: 2024,
    propulsion: VehiclePropulsion.ICE,
  },
  organization: { id: '0234', name: 'org name' },
  hub: { id: '32', name: 'hub name' },
  fleet: { id: '456', name: 'fleet name' },
  creationDate: '2021-08-12T20:16:32.5688490Z',
};

export const DEFAULT_MOCK_ASSET: Required<Asset> = {
  id: '400',
  name: mockAssetNames[0],
  state: MOCK_ASSET_STATE,
  licensePlate: 'abc123',
  status: MOCK_ASSET_TELEMETRY,
  organizationPrimaryOwnershipStatus: OrganizationOwnershipStatus.CLAIMED,
  configuration: {
    autolockTimeout: 5000,
    autolockEnabled: true,
    proximityUnlockEnabled: false,
    chargingScheduleEnabled: ChargingSchedule.ON,
  },
  events: [],
  locations: [
    {
      ...MOCK_LOCATION,
      timestamp: '2021-08-12T20:16:32.5688490Z',
    },
    {
      ...MOCK_LOCATION,
      timestamp: '2021-08-12T20:20:32.5688490Z',
    },
  ],
  type: {
    model: AssetProductModel.TRACE,
    version: '1.0',
    class: AssetType.EP,
    apiType: ApiAssetType.EP1,
  },
  organization: {
    id: '100',
    name: 'Burns Industries',
  },
  hub: {
    id: '200',
    name: 'Alpha',
  },
  fleet: {
    id: '300',
    name: 'Starfleet',
  },
  creationDate: '2021-08-12T20:16:32.5688490Z',
  lastUpdated: '2022-08-12T20:16:32.5688490Z',
  flags: MOCK_ASSET_FLAGS,
  faults: {
    numberOfFaults: 1,
    hasCriticalFault: true,
    numberOfCriticalFaults: 1,
    hasImpairedFault: false,
  },
  pressureRecommendation: true,
  faultsData: [
    {
      id: '0x230',
      code: '--',
      severity: DiagnosticSeverity.CRITICAL,
      deviceTimestamp: '2021-11-01T16:12:40.594545900',
      status: AssetFaultStatus.ACTIVE,
      mil: true,
      name: 'Something bad',
      text: 'Something bad that happens sometimes',
      recommendation: [],
    },
  ],
  operationalStatus: AssetOperationalStatus.OFFLINE,
  utilization: [],
  vehicleType: {
    make: 'Chevrolet',
    year: 2024,
    model: 'Blazer',
    propulsion: VehiclePropulsion.EV,
  },
  raw: MOCK_API_ASSET,
};

export const ASSET_COMPLIANCE_DETAIL: Required<AssetComplianceDetail> = {
  id: '1234abcd5678',
  name: mockAssetNames[0],
  type: { model: AssetProductModel.ZEVO, version: 'EV600' },
  organization: { id: '0234', name: 'org name' },
  hub: { id: '32', name: 'hub name' },
  fleet: { id: '456', name: 'fleet name' },
  safetyCompliance: {
    value: false,
    lastUpdated: '2023-01-31T10:10:10.000Z',
    complianceStatus: {
      buckleToDriveEnabled: true,
      speedWarningEnabled: true,
      supervisedDriveModeEnabled: true,
    } as SafetyComplianceStatusDetail,
  } as SafetyComplianceDetail,
  chargingCompliance: {
    value: false,
    complianceStatus: {
      chargingScheduleCompliant: false,
      homeLocationCompliant: false,
    },
  },
};

export const MOCK_ASSETS: Required<Asset>[] = mockAssetNames.map(
  (name, index) => {
    return createMock(DEFAULT_MOCK_ASSET, createMockValue(index, name));
  }
);

export const MOCK_EPALLET_MODELS: AssetModel[] = [
  { model: 'EP1', version: '1.1' },
  { model: 'EP1', version: '1.2 LV' },
  { model: 'EP1', version: '1.2 HV' },
];

export const MOCK_TRACKER_SERIALS: string[] = [
  'P024AB03557J2XF',
  'P024AB03557J2X12',
  'E024AB03557J2XF',
];

export const mapAssetToReportItem = ({
  id,
  name,
  organization,
  hub,
  fleet,
  ...asset
}: Asset): AssetReportItem => {
  return {
    id,
    name,
    organization,
    hub,
    fleet,
    type: {
      model: asset?.type?.model,
      version: asset?.type?.version,
    },
    ready: asset?.flags?.ready,
    battery: {
      isCharging: asset?.status?.batteryStatus?.charge?.value,
      range: asset?.status?.batteryStatus?.batteryRange,
      value: asset?.status?.batteryStatus?.battery?.value,
    },
    connectivity: asset?.state?.connectivity
      ? lowercaseLiteral(asset?.state?.connectivity)
      : undefined,
    brakeFluid: asset?.flags?.lowBrakeFluid
      ? ReportDiagnosticState.CRITICAL
      : ReportDiagnosticState.GOOD,
    washerFluid: asset?.flags?.lowWasherFluid
      ? ReportDiagnosticState.IMPAIRED
      : ReportDiagnosticState.GOOD,
    faultCount: asset?.faults?.numberOfCriticalFaults,
    leftRearTire: asset?.status?.tirePressures?.leftRear,
    leftFrontTire: asset?.status?.tirePressures?.leftFront,
    rightFrontTire: asset?.status?.tirePressures?.rightFront,
    rightRearTire: asset?.status?.tirePressures?.rightRear,
    estimatedRange: asset?.status?.estimatedRange,
    tires: asset?.flags?.flatTire
      ? ReportDiagnosticState.CRITICAL
      : asset?.flags?.lowTire
      ? ReportDiagnosticState.IMPAIRED
      : asset?.flags?.lowTire === undefined &&
        asset?.flags?.flatTire === undefined
      ? ReportDiagnosticState.NO_DATA
      : ReportDiagnosticState.GOOD,
  } as AssetReportItem;
};

const createMockComplianceValue = (
  id: number,
  name: string
): Partial<AssetComplianceDetail> => {
  return {
    id: (ASSET_COMPLIANCE_DETAIL.id + id).toString(),
    name: name,
    type: {
      model: id % 2 === 0 ? AssetProductModel.ZEVO : AssetProductModel.TRACE,
      version: id % 2 === 0 ? 'EV600' : '1.2',
    },
    organization: { id: '0234', name: 'org name' },
    hub: { id: '32', name: 'hub name' },
    fleet: { id: '456', name: 'fleet name' },
    safetyCompliance: {
      value: false,
      lastUpdated: '2023-01-31T10:10:10.000Z',
      complianceStatus:
        id % 2 === 0
          ? {
              buckleToDriveEnabled: id % 4 === 1 ? true : false,
              speedWarningEnabled: id % 3 === 0 ? false : true,
              supervisedDriveModeEnabled: id % 3 === 1 ? true : false,
            }
          : { autolockEnabled: id % 4 === 1 ? false : true },
    } as SafetyComplianceDetail,
  };
};
export const MOCK_ASSET_COMPLIANCE_DETAIL: AssetComplianceDetail[] =
  mockAssetNames.map((name, index) => {
    return createMock(
      ASSET_COMPLIANCE_DETAIL,
      createMockComplianceValue(index, name)
    );
  });

export const mockAssetsState: Pick<RootState, 'assets'> = {
  assets: {
    ...populateStateSlice('assets', MOCK_ASSETS).assets,
    sessionConfigs: {},
    assetEvents: {
      ids: [],
      entities: {} as Dictionary<AssetEvent>,
    },
    assetReportItems: {
      ids: MOCK_ASSETS.map((entity) => entity.id),
      entities: MOCK_ASSETS.reduce(
        (dict, entity) => ({
          ...dict,
          [entity.id]: mapAssetToReportItem(entity),
        }),
        {}
      ),
    },
    assetComplianceList: {
      ids: MOCK_ASSET_COMPLIANCE_DETAIL.map((entity) => entity.id),
      entities: MOCK_ASSET_COMPLIANCE_DETAIL.reduce(
        (dict, entity) => ({
          ...dict,
          [entity.id]: entity,
        }),
        {}
      ),
    },
    startDate: startDateTime().toString(),
    endDate: endDateTime().toString(),
  },
};

export const mockAssetWithEventsState: Pick<RootState, 'assets'> = {
  assets: {
    ...mockAssetsState.assets,
    assetEvents: getMockAssetEventsAdapterState(MOCK_ASSETS[0].id),
  },
};

export const mockCustomAssetsState = (
  assets: Required<Asset>[]
): Pick<RootState, 'assets'> => ({
  assets: {
    ...mockAssetsState.assets,
    ...populateStateSlice('assets', assets).assets,
  },
});

export const MOCK_TIRE_READINESS_ITEM: ApiTiresReadinessItem = {
  connectivity: AssetConnectivityStatus.OFFLINE,
  fleet: { id: 'fb0d2101-5f99-4b0a-9e16-beb946a62d9c', name: 'fleet test' },
  hub: { id: '5d3084e6-b3f6-4862-9da6-9851e628edb1', name: 'hub test' },
  id: 'gyfyul23489938888',
  name: 'Onstar Asset - Updated3',
  organization: {
    id: 'fe32ad2c-ddca-4c30-8305-d931e699cefe',
    name: 'Extended Foods Distribution',
  },
  tires: {
    leftFront: {
      pressureInPSI: 72.51631617113851,
      state: ReportDiagnosticState.GOOD,
    },
    rightFront: {
      pressureInPSI: 46.55547498187092,
      state: ReportDiagnosticState.GOOD,
    },
    leftRear: {
      pressureInPSI: 8.701957940536621,
      state: ReportDiagnosticState.CRITICAL,
    },
    rightRear: {
      pressureInPSI: 45.83031182015954,
      state: ReportDiagnosticState.IMPAIRED,
    },
  },
  type: { model: 'ELCV', version: '600' },
  availabilityState: AssetServiceStatus.IN_SERVICE,
  licensePlate: 'kl-34647',
  vehicleType: {
    year: 2024,
    make: 'Zevo',
    model: '600',
    propulsion: VehiclePropulsion.EV,
  },
};

export const MOCK_API_READINESS_ITEM: ApiReadinessItem = {
  id: '00000777',
  name: 'mockItemName',
  type: {
    model: 'ELCV',
    version: 'EV600',
  },
  organization: DEFAULT_MOCK_ASSET.organization,
  hub: DEFAULT_MOCK_ASSET.hub,
  fleet: DEFAULT_MOCK_ASSET.fleet,
  ready: ReadyStatus.NOT_READY,
  faultCount: 5,
  battery: {
    state: ReportDiagnosticState.CRITICAL,
    soc: 10,
    chargeState: BatteryChargingStatus.NOT_CHARGING,
    estimatedRangedInKM: 10000,
  },
  tires: undefined,
  brakeFluid: ReportDiagnosticState.GOOD,
  washerFluid: ReportDiagnosticState.IMPAIRED,
  connectivity: AssetConnectivityStatus.OFFLINE,
  lastUpdated: '2023-07-21T22:50:22.0000000Z',
  safetyCompliant: true,
  milState: ReportDiagnosticState.CRITICAL,
  diagnosticState: ReportDiagnosticState.CRITICAL,
  availabilityState: AssetServiceStatus.IN_SERVICE,
  fuelLevel: {
    state: ReportDiagnosticState.IMPAIRED,
    fuelLevel: 45,
  },
  licensePlate: 'kl-34647',
  oilLife: {
    state: ReportDiagnosticState.CRITICAL,
    oilLife: 5,
  },
  brakePadRear: {
    brakePadLife: 40,
    state: ReportDiagnosticState.GOOD,
  },
  brakePadFront: {
    brakePadLife: 2,
    state: ReportDiagnosticState.CRITICAL,
  },
  engineAirFilter: {
    engineAirFilter: 4,
    state: ReportDiagnosticState.CRITICAL,
  },
  vehicleType: {
    year: 2024,
    make: 'Zevo',
    model: '600',
    propulsion: VehiclePropulsion.EV,
  },
  lastCommunicated: {
    deviceTimestamp: '2023-08-07T10:10:10.000Z',
    status: 'Offline',
  },
  provisioning: ProvisioningStatus.IN_PROGRESS,
  organizationPrimaryOwnershipStatus: OrganizationOwnershipStatus.CLAIMED,
  diagnostics: [
    {
      state: ReportDiagnosticState.CRITICAL,
      type: DiagnosticDTCType.BRAKE_FLUID,
      deviceTimestamp: '2024-08-07T10:10:10.000Z',
    },
    {
      state: ReportDiagnosticState.CRITICAL,
      type: DiagnosticDTCType.TIRE,
      deviceTimestamp: '2024-08-07T10:10:10.000Z',
      value: 123,
    },
    {
      state: ReportDiagnosticState.IMPAIRED,
      type: DiagnosticDTCType.BRAKE_PAD,
      deviceTimestamp: '2024-08-07T10:10:10.000Z',
      value: 15,
    },
    {
      state: ReportDiagnosticState.IMPAIRED,
      type: DiagnosticDTCType.ENGINE_AIR_FILTER,
      deviceTimestamp: '2024-08-07T10:10:10.000Z',
    },
  ],
};

export const MOCK_READINESS_ITEM = mapApiAssetReadinessToReportItem(
  MOCK_API_READINESS_ITEM
);

export const MOCK_API_BATTERY_READINESS_ITEM: ApiBatteryReadinessItem = {
  id: '00000777',
  name: 'mockItemName',
  type: {
    model: 'ELCV',
    version: 'EV600',
  },
  organization: DEFAULT_MOCK_ASSET.organization,
  hub: DEFAULT_MOCK_ASSET.hub,
  fleet: DEFAULT_MOCK_ASSET.fleet,
  connectivity: AssetConnectivityStatus.ONLINE,
  lastUpdated: '2023-08-07T10:10:10.000Z',
  battery: {
    state: ReportDiagnosticState.CRITICAL,
    soc: 70,
    estimatedRangedInKM: 350,
    targetSoc: 80,
    chargeState: BatteryChargingStatus.NOT_CHARGING,
    chargePowerType: PowerType.AC,
    estimatedTimeRemainingToTargetSOC: 120,
    lastUpdated: '2023-08-07T10:10:10.000Z',
    chargingEstimatedCompletionDateTime: '2023-05-07T10:10:10.000Z',
  },
  licensePlate: 'kl-34647',
  vehicleType: {
    year: 2024,
    make: 'Zevo',
    model: '600',
    propulsion: VehiclePropulsion.EV,
  },
  lastCommunicated: {
    deviceTimestamp: '2023-08-07T10:10:10.000Z',
    status: 'Offline',
  },
};

export const ASSET_MAP_ITEM_API = {
  id: '123',
  name: 'test asset',
  type: {
    model: 'ELCV',
    version: '1.1',
  },
  lastCommunicated: '2021-04-29T14:11:40.594545900',
  location: { lon: 40, lat: 30 },
  operational: {
    speed: 60,
    direction: 180,
  },
  battery: {
    state: ReportDiagnosticState.CRITICAL,
    soc: 5,
    chargeState: BatteryChargingStatus.NOT_CHARGING,
    estimatedRangedInKM: 40,
    chargePowerType: PowerType.AC,
  },
  brakeFluid: ReportDiagnosticState.CRITICAL,
  washerFluidState: ReportDiagnosticState.CRITICAL,
  tires: ReportDiagnosticState.IMPAIRED,
  ready: false,
  faultCount: 10,
  milState: ReportDiagnosticState.CRITICAL,
  availability: AssetServiceStatus.IN_SERVICE,
} as ApiAssetMapItem;
