import { useFeatureFlags } from '@brightdrop/feature-flags-client';
import { useTranslations } from '@brightdrop/localization-client';
import { NavBarConfig } from '@gm-commercial/navbar-model';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useAppDispatch } from '~/app/store';
import { SecureWrapper } from '~/common/components';
import useSessionId from '~/common/hooks/useSessionId';
import { getDefaultRoutePath } from '~/common/utils/route/route.utils';

import {
  selectActingPermissionsContextIds,
  selectActingRole,
} from '../profile/profileSlice.selectors';
import {
  getAppNameFromPath,
  getCSNavBarConfig,
} from './config/cs.navDrawer.config';
import CsNavDrawer from './CsNavDrawer';
import { CS_NAVBAR_MESSAGES } from './CsNavDrawer.messages';

const selectActingProfileParams = createSelector(
  selectActingRole,
  selectActingPermissionsContextIds,
  (role, contextIds) => {
    return { role, ...contextIds };
  }
);

/**
 * Removes any config items or children with an empty path.
 * @param {NavBarConfig} config - The config object to sanitize
 */
const sanitizeConfig = (config: NavBarConfig): NavBarConfig => {
  return {
    ...config,
    items: config.items.filter((item) => {
      // Check if the item is enabled
      if (item.path === '') {
        return false;
      }

      // Recursively filter children if they exist
      if (item.children) {
        item.children = sanitizeConfig({
          items: item.children,
        }).items;
      }

      return true;
    }),
  };
};

const NavDrawerWrapper = () => {
  const { translations } = useTranslations(CS_NAVBAR_MESSAGES);
  const { pathname } = useLocation();
  const { getFlag } = useFeatureFlags();
  const profileParams = useSelector(selectActingProfileParams);
  const defaultRoutePath = profileParams?.role
    ? getDefaultRoutePath(profileParams?.role, profileParams, getFlag)
    : '/';
  const appName = getAppNameFromPath(pathname);
  const dispatch = useAppDispatch();
  const sessionId = useSessionId();

  const navBar = useMemo(() => {
    const config = sanitizeConfig(
      getCSNavBarConfig({
        appName,
        messages: translations,
        profileParams,
        sessionId,
        dispatch,
        findFlagScope: getFlag,
      })
    );
    return <CsNavDrawer {...config} homePath={defaultRoutePath} />;
  }, [
    appName,
    defaultRoutePath,
    profileParams,
    translations,
    sessionId,
    dispatch,
    getFlag,
  ]);

  return (
    <>
      <SecureWrapper requiredPermissions={[]} passThrough>
        {navBar}
      </SecureWrapper>
    </>
  );
};

export default NavDrawerWrapper;
