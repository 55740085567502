import type { EnumValues } from './DtcCard';

export const DTC_SYSTEMS = {
  airbag: 'airbag',
  antilock_braking: 'antilock_braking',
  high_voltage_battery: 'high_voltage_battery', // Battery System
  electric_drive: 'electric_drive',
  emissions: 'emissions',
  onstar: 'onstar',
  stability_control: 'stability_control',
  engine_and_transmission: 'engine_and_transmission',
  vehicle_battery: 'vehicle_battery',
} as const;

// Only used for OVI detail text at the moment
export const VEHICLE_SYSTEMS = {
  ...DTC_SYSTEMS,
  air_filter: 'air_filter',
  brake_fluid: 'brake_fluid',
  brake_pad: 'brake_pad',
  diesel_exhaust_fluid: 'diesel_exhaust_fluid',
  light_bulb: 'light_bulb',
  oil_life: 'oil_life',
  regenerative_braking: 'regenerative_braking',
  tire_pressure: 'tire_pressure',
  washer_fluid: 'washer_fluid',
} as const;

export type DtcSystems = EnumValues<typeof DTC_SYSTEMS>;
export type VehicleSystems = EnumValues<typeof VEHICLE_SYSTEMS>;

export const DTC_TRANSLATIONS = {
  [DTC_SYSTEMS.airbag]: 'asset:vehicle.dtc.airbag',
  [DTC_SYSTEMS.antilock_braking]: 'asset:vehicle.dtc.abs',
  [DTC_SYSTEMS.electric_drive]: 'asset:vehicle.dtc.electricDrive',
  [DTC_SYSTEMS.emissions]: 'asset:vehicle.dtc.emissions',
  [DTC_SYSTEMS.high_voltage_battery]: 'asset:vehicle.dtc.highVoltageBattery',
  [DTC_SYSTEMS.onstar]: 'asset:vehicle.dtc.onStar',
  [DTC_SYSTEMS.stability_control]: 'asset:vehicle.dtc.stability',
  [DTC_SYSTEMS.engine_and_transmission]: 'asset:vehicle.dtc.engineTransmission',
  [DTC_SYSTEMS.vehicle_battery]: 'asset:vehicle.dtc.vehicleBattery',
};

export const DTC_DATA_TEST_IDS = {
  [DTC_SYSTEMS.airbag]: 'vehicle-details-issues-airbag-system',
  [DTC_SYSTEMS.antilock_braking]:
    'vehicle-details-issues-antilock-breaking-system',
  [DTC_SYSTEMS.electric_drive]: 'vehicle-details-issues-electric-drive-unit',
  [DTC_SYSTEMS.emissions]: 'vehicle-details-issues-emission-system',
  [DTC_SYSTEMS.high_voltage_battery]: 'vehicle-details-issues-battery-system',
  [DTC_SYSTEMS.onstar]: 'vehicle-details-issues-onstar-system',
  [DTC_SYSTEMS.stability_control]:
    'vehicle-details-issues-stability-control-system',
  [DTC_SYSTEMS.engine_and_transmission]:
    'vehicle-details-issues-engine-and-transmission',
  [DTC_SYSTEMS.vehicle_battery]: 'vehicle-details-issues-vehicle-battery',
};

type DetailContent = Record<
  EnumValues<typeof VEHICLE_SYSTEMS>,
  {
    explanation: string;
    recommendation: string | null;
  }
>;

export const DTC_DETAIL_CONTENT: DetailContent = {
  // Can't find this one in OVI
  [VEHICLE_SYSTEMS.light_bulb]: {
    explanation: ``,
    recommendation: null,
  },

  [VEHICLE_SYSTEMS.antilock_braking]: {
    explanation: `This system is responsible for monitoring and controlling the anti-lock brakes, which can reduce stopping distance in slippery conditions, and the traction control/stability system, which helps the vehicle maintain control in adverse conditions.

The anti-lock braking system includes the following:

    - Anti-lock braking system
    - Traction control system (if fitted)`,
    recommendation: `Please service your vehicle. Please see your owner's manual for more information and contact your dealer or fleet manager to check your vehicle.`,
  },
  [VEHICLE_SYSTEMS.airbag]: {
    explanation: `This system is responsible for monitoring and deploying the airbag system.

The airbag system includes the following:

    - Airbag deployment system
    - Collision sensor system
    - Passenger presence system (if fitted)
    - Seatbelt pretensioner system (if fitted)`,
    recommendation: null,
  },
  [VEHICLE_SYSTEMS.air_filter]: {
    explanation: `The engine air filter traps airborne particles before they enter the engine and helps supply the best possible air with the least airflow restriction to the engine. It maximizes the performance and efficiency of the engine and helps with its life.  Replacing your vehicle's filters on a regularly scheduled basis may help the vehicle run more efficiently.`,
    recommendation: `If equipped, the engine air filter life system provides the engine air filter's remaining life and best timing for a change. The timing to change an engine air filter depends on driving and environmental conditions.  Engine air filter life 95% means 95% of the current air filter life remains.`,
  },
  [VEHICLE_SYSTEMS.brake_fluid]: {
    explanation: `Brake fluid is a liquid solution used in the hydraulic braking systems to amplify braking force. There are only two reasons why the brake fluid level in the reservoir may go down:
    Normal brake lining wear. When new linings are installed, the fluid level goes back up.
    A fluid leak in the brake hydraulic system. Have the brake hydraulic system fixed. With a leak, the brakes will not work well.
    Do not top off the brake fluid. Adding fluid does not correct a leak. If fluid is added when the linings are worn, there will be too much fluid when new brake linings are installed. Add or remove fluid, as necessary, only when work is done on the brake hydraulic system.`,
    recommendation: `Brake fluid absorbs water over time which degrades the effectiveness of the brake fluid. Replace brake fluid at the intervals specified in the vehicle's owner's manual to prevent increased stopping distance.`,
  },
  [VEHICLE_SYSTEMS.brake_pad]: {
    explanation: `A well-maintained braking system is crucial for a safe and successful drive.  Brake pads need to be repaired or replaced as part of regular brake service.  Brake pad life depends on driving habits, vehicle usage, and operating environment. Brake systems are designed to provide 20,000 to 25,000 miles of pad life in very severe use (such as heavy-traffic urban areas) and will give 40,000 to 60,000 miles of pad life in average use. Factors that will reduce pad life include frequent heavy braking, elevated temperatures (caused by high-speed braking, driving in mountainous areas), driving with the vehicle heavily loaded, and severe environments such as high-corrosion areas and areas with a lot of road debris and dust.`,
    recommendation: null,
  },
  [VEHICLE_SYSTEMS.diesel_exhaust_fluid]: {
    explanation: `Diesel Exhaust Fluid (DEF) is a non-hazardous solution, which is sprayed into the exhaust stream of diesel vehicles to break down dangerous emissions into harmless nitrogen and water.`,
    recommendation: `Diesel exhaust fluid is low.

If the Diesel exhaust fluid is not refilled soon, the vehicle will be limited to a speed of 5 mph until the DEF tank is refilled.`,
  },
  [VEHICLE_SYSTEMS.electric_drive]: {
    explanation: `This system is responsible for propelling the vehicle.

The electric drive unit includes the following:

    - Motor control system
    - Accessory power
    - Auxiliary transmission pump system`,
    recommendation: null,
  },
  [VEHICLE_SYSTEMS.emissions]: {
    explanation: `This system is responsible for limiting emissions from the vehicle, both by keeping fuel and its vapour contained in the fuel system and by limiting exhaust tailpipe emissions by ensuring efficient engine operation.

NOTE: If your vehicle's Check Engine/Service Engine Soon light is on or not working properly, your vehicle requires service and may not pass your local emissions inspection programme (where applicable).

The emissions system includes the following:

    - Evaporative emissions system
    - Exhaust emissions system`,
    recommendation: `A problem with the Emissions System in your vehicle has been detected.

Service within 7 days`,
  },
  [VEHICLE_SYSTEMS.engine_and_transmission]: {
    explanation: `This system is responsible for creating the power necessary to move the vehicle and transmitting that power to the wheels.

NOTE: If your vehicle's Check Engine/Service Engine Soon light is on or not working properly, your vehicle requires service and may not pass your local emissions inspection programme (where applicable).

The Engine and Transmission system includes the following:

    - Induction system
    - Fuel management system
    - Engine cooling system
    - Throttle control system
    - Variable valve timing system (if fitted)
    - Displacement on Demand™/Active Fuel Management System™
    - Ignition system
    - Engine electrical system including the charging and starting system
    - Hybrid powertrain system (if fitted)
    - Electric propulsion system (if fitted)
`,
    recommendation: null,
  },
  [VEHICLE_SYSTEMS.high_voltage_battery]: {
    explanation: `This system is responsible for providing the electrical energy that drives the wheels of the vehicle. This battery can be charged from an external current.

The battery system includes the following:

    - High voltage battery system
    - Battery thermal conditioning system
    - Onboard charging system
    - Plug-in charging system`,
    recommendation: null,
  },
  [VEHICLE_SYSTEMS.oil_life]: {
    explanation: `Engine oil lubricates the engine parts, which reduces friction and protects the engine.

It is recommended to regularly change the oil.`,
    recommendation: `Please see your dealer for service.`,
  },
  [VEHICLE_SYSTEMS.onstar]: {
    explanation: `This system is responsible for the operation of your OnStar system.

The OnStar system includes the following:

    - Wireless communication system
    - Global Positioning System (GPS)
    - Peripheral systems
  `,
    recommendation: null,
  },
  [VEHICLE_SYSTEMS.regenerative_braking]: {
    explanation: `Regenerative braking takes some of the energy from the moving vehicle and turns it back into electrical energy.

This energy is then stored in the high voltage battery system, so helping to improve energy efficiency. The hydraulic disc brakes work with the regenerative braking system to ensure effective braking, such as when heavy braking is requested.

The regenerative braking system includes the following:

    - Regenerative braking system
    - Traction control system`,
    recommendation: `Press the blue OnStar button and request an On-Demand Diagnostics check, or schedule a service visit to your dealer as soon as possible.`,
  },
  [VEHICLE_SYSTEMS.stability_control]: {
    explanation: `The stability control system is an advanced computer-controlled system that can help the driver maintain directional control of the vehicle in difficult driving conditions.

This is accomplished by using input from vehicle motion sensors and the steering wheel position to selectively apply any one of the vehicle's brakes and, if necessary, reduce engine power.

When the system is activated, a Stability Active light may come on or a Stability Active message may be displayed. This is normal. If the system detects a problem, the Stability Disabled or Service Stability light or message will be displayed.

If the light or message remains on even after pressing the Traction/Stability selector button, your vehicle may require service. This information will also be sent with your OnStar Vehicle Diagnostics email to alert you that service may be needed.`,
    recommendation: null,
  },
  [VEHICLE_SYSTEMS.tire_pressure]: {
    explanation: `Keeping your tires inflated correctly is essential if you want to maximize your overall energy efficiency. Correctly inflated tires are also safer and last longer.
You may see a variation in tire pressure readings in extreme weather conditions. You should confirm the pressure of each tire before adjusting these pressures.`,
    recommendation: `Please inspect before driving. You need to inflate or change the tire(s).`,
  },
  [VEHICLE_SYSTEMS.vehicle_battery]: {
    explanation: `The car battery's main purpose is to feed the starter which starts the engine.

A car battery will typically last three or four years before wearing out.`,
    recommendation: `Please start your vehicle to begin recharging your battery. It is recommended to contact your dealer or fleet manager and arrange for a battery check.`,
  },
  [VEHICLE_SYSTEMS.washer_fluid]: {
    explanation: `Washer fluid is used for cleaning the windshield with the wipers to help keep vision clear.
When windshield washer fluid needs to be added, be sure to read the manufacturer's instructions before use. Use a fluid that has sufficient protection against freezing in an area where the temperature may fall below freezing.`,
    recommendation: null,
  },
} as const;
