import { BDContainer, BDPageSpinner } from '@brightdrop/bd-ui';
import { useTranslations } from '@brightdrop/localization-client';
import { Box, Container, Typography } from '@mui/material';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { AlertMessage } from '~/common/components';
import { Routes } from '~/common/configs/route.config';
import { AppNames } from '~/common/models/appNames.model';
import { AppRoutePaths } from '~/common/models/route.model';
import { getAppNameFromPath } from '~/features/navigationDrawer/config/cs.navDrawer.config';
import { DEFAULT_AUTHENTICATION_TYPE } from '~/features/session/model/authentication.model';

import LoginTemplate from '../../common/components/login/LoginTemplate';
import SecureRoute from '../../features/secureRoute/SecureRoute';
import AuthTokenWrapper from '../../features/session/AuthTokenWrapper';
import InboundRedirect from '../../features/session/login/InboundRedirect';
import RouteErrorBoundary from '../errorFallback/RouteErrorBoundary';

export const MESSAGES = {
  'common:filterKeywords': 'FILTER KEYWORDS',
  'common:alertMsg.messageView.pageNotFound.header': 'Page not found',
  'common:alertMsg.messageView.pageNotFound.button': 'Return home',
  'common:alertMsg.messageView.unauthorized.header': 'Missing Permissions',
  'common:alertMsg.messageView.unauthorized.body':
    'Please contact an account administrator for access.',
  'common:alertMsg.messageView.unauthorized.button': 'Return home',
  'common:alertMsg.messageView.inviteRequired.header': 'Invite Required',
  'common:alertMsg.messageView.inviteRequired.body':
    'Please contact your manager for access to the BrightDrop application.',
  'common:alertMsg.messageView.noBusinessFound.header': 'No business found',
  'common:alertMsg.messageView.noBusinessFound.body':
    'Your GM account is not associated with a business. Please contact an account administrator.',
  'common:alertMsg.messageView.serviceUnavailable.header':
    'Temporarily Unavailable',
  'common:alertMsg.messageView.serviceUnavailable.body': `This page isn't available right now. Please try again later.`,
  'common:alertMsg.messageView.serviceUnavailable.button': 'Return home',

  'common:alertMsg.messageView.mobileAppRequired.header': 'Mobile App Required',
  'common:alertMsg.messageView.mobileAppRequired.body':
    'BrightDrop Operator may only access the system through the BrightDrop mobile app.  Please contact your manager to request access to the BrightDrop mobile application.',
};
const Auth = lazy(() => import('../../features/session/Auth'));

const Support = lazy(() => import('mf-support/SupportView'));

const AppContent = (): JSX.Element => {
  const { translations } = useTranslations(MESSAGES);
  const { pathname, search } = useLocation();

  const appName = getAppNameFromPath(pathname);
  const onAuthPage = appName === AppNames.AUTH;

  return (
    <BDContainer id="fleet-management">
      {!onAuthPage && <AuthTokenWrapper />}
      <Container maxWidth={false} className="contentContainer">
        <Box
          flex="1"
          className={`contentWrapper ${onAuthPage ? 'noPagePadding' : ''}`}
          position="relative"
        >
          <Suspense fallback={<BDPageSpinner />}>
            <RouteErrorBoundary>
              <Switch>
                <Route exact path="/" component={InboundRedirect} />
                <Route exact path="/login">
                  <Redirect
                    to={`/auth/${DEFAULT_AUTHENTICATION_TYPE}/login/${search}`}
                  />
                </Route>
                {/*Adding simple redirect from sso to the auth/sso/login page*/}
                {/* Adding login to below url will result auth/sso being added to the url twice due to redirect above*/}
                <Route exact path="/sso">
                  <Redirect to={'auth/sso'} />
                </Route>
                <Route path="/auth" component={Auth} />
                {Routes.map((route, index) =>
                  route.secure ? (
                    <SecureRoute key={index} exact {...route} />
                  ) : (
                    <Route key={index} exact {...route} />
                  )
                )}
                <SecureRoute
                  exact
                  path={AppRoutePaths.MOBILE_APP_REQUIRED}
                  authorizedPerms={[]}
                  render={() => (
                    <AlertMessage
                      header={
                        translations[
                          'common:alertMsg.messageView.mobileAppRequired.header'
                        ]
                      }
                      body={
                        translations[
                          'common:alertMsg.messageView.mobileAppRequired.body'
                        ]
                      }
                      showGraphic
                      topic="mobileAppRequired"
                      id="mobile-app-required"
                    />
                  )}
                ></SecureRoute>
                <Route path={AppRoutePaths.LOGOUT}>
                  <Redirect
                    to={`/auth/${DEFAULT_AUTHENTICATION_TYPE}/logout`}
                  />
                </Route>
                <Route
                  exact
                  path={AppRoutePaths.PAGE_NOT_FOUND}
                  render={() => (
                    <AlertMessage
                      header={
                        translations[
                          'common:alertMsg.messageView.pageNotFound.header'
                        ]
                      }
                      linkBtn={
                        translations[
                          'common:alertMsg.messageView.pageNotFound.button'
                        ]
                      }
                      topic="pageNotFound"
                      id="page-not-found"
                    />
                  )}
                ></Route>
                <Route
                  exact
                  path={AppRoutePaths.UNAUTHORIZED}
                  render={() => (
                    <AlertMessage
                      header={
                        translations[
                          'common:alertMsg.messageView.unauthorized.header'
                        ]
                      }
                      body={
                        translations[
                          'common:alertMsg.messageView.unauthorized.body'
                        ]
                      }
                      linkBtn={
                        translations[
                          'common:alertMsg.messageView.unauthorized.button'
                        ]
                      }
                      topic="unauthorizedError"
                      id="unauthorized-access"
                    />
                  )}
                ></Route>
                <Route
                  exact
                  path={AppRoutePaths.INVITE_REQUIRED}
                  render={() => (
                    <LoginTemplate>
                      <Box mt={2}>
                        <Typography
                          variant="header1_medium_deprecated"
                          data-testid="terms-condition-title"
                        >
                          {
                            translations[
                              'common:alertMsg.messageView.noBusinessFound.header'
                            ]
                          }
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        {
                          translations[
                            'common:alertMsg.messageView.noBusinessFound.body'
                          ]
                        }
                      </Box>
                    </LoginTemplate>
                  )}
                ></Route>

                <Route
                  exact
                  path={AppRoutePaths.SERVICE_UNAVAILABLE}
                  render={() => (
                    <AlertMessage
                      header={
                        translations[
                          'common:alertMsg.messageView.serviceUnavailable.header'
                        ]
                      }
                      body={
                        translations[
                          'common:alertMsg.messageView.serviceUnavailable.body'
                        ]
                      }
                      linkBtn={
                        translations[
                          'common:alertMsg.messageView.serviceUnavailable.button'
                        ]
                      }
                      topic="serviceUnavailable"
                      id="service-unavailable"
                    />
                  )}
                ></Route>
                <Route path={AppRoutePaths.SUPPORT} component={Support} />
                <Redirect to={AppRoutePaths.PAGE_NOT_FOUND} />
              </Switch>
            </RouteErrorBoundary>
          </Suspense>
        </Box>
      </Container>
    </BDContainer>
  );
};
export default AppContent;
