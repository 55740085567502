import { OperationStatus } from './apis/apiResponse.model';

export enum FeatureFlagsRequestType {
  GET_FEATURE_FLAGS = 'Get Feature Flags',
}

export type FeatureFlagValueType =
  | string
  | boolean
  | number
  | { [k: string]: FeatureFlagValueType };

export type FeatureFlag = {
  name: string;
  value: FeatureFlagValueType;
};

export type FeatureFlagsState = {
  operations: {
    [key in FeatureFlagsRequestType]?: OperationStatus;
  };
};

export const SIMULATION_FLAG_NAME = 'F21120-Simulation-Portal';
export const GROCERY_FLAG_NAME = 'F32356-Cold-Chain';
export const TRIP_HISTORY_SUPPRESSOR_FLAG_NAME =
  'F44668-Trip-History-Suppressor';
export const CHARGE_STATUS_FLAG_NAME = 'F37524-Charge-Status';
export const CHARGING_SCHEDULE_FLAG_NAME = '34205-Charging-Schedule-Config';
export const INSIGHTS_FLAG_NAME = 'F41450-Insights';
export const DVIC_FLAG_NAME = 'F37416-DVIC-MVP';
export const DISPATCH_MANAGEMENT_MVP_FLAG_NAME =
  'F44231-Dispatch-Management-MVP';
export const NEW_LOCATIONS_FLAG_NAME = 'S44669-Locations-Upgrade';
export const TRACE_SUPPRESSOR_FLAG_NAME = 'F44574-Trace-Suppressor';
export const UTILIZATION_SUMMARY_ALL_TIME_FILTER_FLAG_NAME =
  'F45825-Utilization-Summary-All-Time-Filter';
export const ASSIGN_VEHICLE_FLAG_NAME = 'S50708-ASSIGN-VEHICLE';
export const TRIP_LOGGER_FLAG_NAME = 'S50763-TRIP-LOGGER';
export const CONNECTED_CAMERAS_FLAG_NAME = 'F51515-CONNECTED-CAMERAS';
export const MRT_FLAG_NAME = 'F52091-MRT-FLAG';
// Charge Level Widget Added to Dashboard View 9/19/2024
export const DASHBOARD_CHARGE_LEVEL = 'F52359-Charge-Level-Widget';
export const DIAGNOSTICS_FLAG_NAME = 'F50012-DIAGNOSTICS-COLUMNS';
export const REMOTE_COMMANDS_FLAG_NAME = 'F53692-Remote-Commands';
export const CAPS_FLAG_NAME = 'F52239-CAP-AGREEMENTS-PAGE';
